import {
  Typography,
  Grid,
  Button,
  Box,
  Avatar,
  createTheme,
  TextField,
} from "@mui/material";

import AppleIcon from "@mui/icons-material/Apple";
import { useTranslation } from "react-i18next";
import OrderedItems from "../Invoice/OrderedItems";
import PoweredBy from "../../components/PoweredBy/PoweredBy";
import { useState } from "react";
import Slider from "../../components/Slider/Slider";
import TrackPayment from "../../components/TrackPayment/TrackPayment";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

import { SplitData } from "../../constants/JsonObjects";
import { useNavigate } from "react-router-dom";
import lightThemeOptions from "../../theme/lightThemeOptions";
import TipsComponent from "../../components/tipsComponent/TipsComponent";

const lightTheme = createTheme(lightThemeOptions);

const SplitBill: React.FC = () => {
  const { t ,i18n} = useTranslation();
  const navigate = useNavigate();

  const [trackPayment, setTrackPayment] = useState(false);
  const [totalAmount, setTotalAmount] = useState(0);
  const [customAmount, setCustomAmount] = useState<number | null>(null);

  const handleMembership = () => {
    // navigate("/membership");
  };

  const handlePayment = () => {
    // navigate("/membership");
  };

  const handleCustomAmountChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCustomAmount(+event.target.value);
  };

  return (
    <>
      <div
        style={{
          minHeight: window.innerHeight,
          overflowX:'hidden'
        }}
      >
        <Grid
          container
          direction="column"
          alignItems="center"
          padding={1}
          rowSpacing={2}
          justifyContent="space-between"
        >
          {/* Logo */}
          <Grid item sx={classes.Header}>
            <ArrowBackIosNewIcon
              sx={classes.backButton}
              onClick={() => navigate("/payment")}
            />
            <img
              alt="logo"
              src={require("../../assets/CDM_AbuDhabi_Logo.png")}
              style={{
                width: 200,
                borderRadius: 10,
                cursor: "pointer",
              }}
            />
          </Grid>

          {/* Slider */}
          <Grid
            item
            sx={{
              minHeight: "150px",
              width: "100%",
            }}
          >
            <Slider />
          </Grid>
          <Box sx={classes.AvatarGroup}>
            {SplitData.map((item) => (
              <Box sx={classes.AvatarBox} key={item.id}>
                <Avatar sx={{ bgcolor: "#BCAF98" }}>
                  {item.name === "you" ? null : item.name.slice(0, 2)}
                </Avatar>
                <Typography>{item.name}</Typography>
              </Box>
            ))}
          </Box>
        </Grid>
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            margin: "1rem",
            marginBottom: "1.5rem",
          }}
        >
          <Typography variant="h6" sx={classes.billTitle}>
            {t("Split")}
          </Typography>

          <Typography
            variant="h6"
            sx={classes.trackPayment}
            onClick={() => setTrackPayment(!trackPayment)}
          >
           {t("Track")}{" "}
            {i18n.language!=="Arabic" && (trackPayment ? <ArrowBackIosNewIcon style={{width:20,height:20}}/> : <ArrowForwardIosIcon style={{width:20,height:20}} />)}
            {i18n.language ==="Arabic" && (!trackPayment ? <ArrowBackIosNewIcon  style={{width:20,height:20}}/> : <ArrowForwardIosIcon  style={{width:20,height:20}} />)}
          </Typography>
        </Box>

        {/* Pay Custom Amount - customAmount */}
        <Grid container direction="column" alignItems="left">
          <Grid item style={{ margin: "1rem",marginTop:0 }}>
            <Typography
              variant="h6"
              style={{
                fontSize: "12px",
                fontWeight: 400,
                lineHeight: "20px",
                color: "#565D6DFF",
              }}
            >
            {t("selectItemsPrompt")}
            </Typography>
          </Grid>

          <Grid item style={{ marginLeft: "1rem", marginRight: "1rem" }}>
            <TextField
              fullWidth
              type="number"
              size="small"
              value={customAmount}
              placeholder={t("EnterAmount")}
              onChange={handleCustomAmountChange}
              style={classes.customAmount}
            />
            <Typography style={{fontFamily:'Inter',fontSize:'12px',color:'#999',marginTop:'0.5rem'}}>
              {t("extratips")}
            </Typography>
          </Grid>
        </Grid>

        {/* Track Payment History */}
        {trackPayment && (
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              marginTop: "1.5rem",
              marginBottom: "1.5rem",
            }}
          >
            <TrackPayment />
          </Box>
        )}

        {/* Ordered Items Table */}
        <Grid
          container
          direction="column"
          alignItems="center"
          rowSpacing={2}
          style={{
            marginTop: "1.5rem",
          }}
        >
          <OrderedItems withSelection={true} TotalSetter={setTotalAmount} />
        </Grid>

        {/* Add a Tip */}
        <TipsComponent TotalAmount={totalAmount} />
        {/* Payment Buttons */}
        <Grid container direction="column" alignItems="center" rowSpacing={2}>
          {/* Apple Pay */}
          <Grid item style={{ width: "90%" }}>
            <Button
              variant="contained"
              sx={classes.appleButton}
              onClick={handlePayment}
            >
              <AppleIcon style={{ marginRight: "8px" }} />
              {t("Pay")}
            </Button>
          </Grid>

          {/* Credit Card Payment */}
          <Grid item style={{ width: "90%" }}>
            <Button
              variant="contained"
              color="primary"
              style={{
                height: "36px",
                borderRadius: "20px",
                width: "100%",
              }}
              onClick={handlePayment}
            >
              {t("CardPay")}
            </Button>
          </Grid>
        </Grid>

        {/* Powered By */}
        <Grid item style={{ textAlign: "center" }}>
        <PoweredBy color="#999"/>
        </Grid>
      </div>
    </>
  );
};

const classes = {
  Header: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    justifyContent: "center",
  },
  backButton: {
    cursor: "pointer",
    position: "absolute",
    left: "25px",
    color: lightTheme.palette.primary.dark,
  },
  appleButton: {
    height: "36px",
    background: "black",
    color: "white",
    borderRadius: "20px",
    width: "100%",
    display: "flex", // Make the button a flex container
    justifyContent: "center", // Center the content horizontally
    alignItems: "center", // Center the content vertically
  },
  membershipButton: {
    height: "49px",
    padding: "0 12px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "26px",
    color: "#FFFFFFFF",
    background: "#BCAF98FF",
    opacity: 1,
    border: "none",
    borderRadius: "11px",
    "&:hover": {
      color: "#FFFFFFFF",
      background: "#A28F70FF",
    },
    "&:active": {
      color: "#FFFFFFFF",
      background: "#827155FF",
    },
    "&:disabled": {
      opacity: 0.4,
    },
  },
  billTitle: {
    fontFamily: "Inter",
    fontSize: "22px",
    fontWeight: 700,
    lineHeight: "34px",
    color: "#171A1FFF",
  },
  trackPayment: {
    fontFamily: "Inter",
    fontSize: "20px",
    fontWeight: 500,
    lineHeight: "34px",
    color: lightTheme.palette.primary.main,
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  splitButton: {
    height: "30px",
    borderRadius: "25px",
    padding: "0 12px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "26px",
    color: "#FFFFFFFF",
    background: "#00BDD6FF",
    opacity: 1,
    border: "none",
    "&:hover": {
      color: "#FFFFFFFF",
      background: "#00A9C0FF",
    },
    "&:active": {
      color: "#FFFFFFFF",
      background: "#0095A9FF",
    },
    "&:disabled": {
      opacity: 0.4,
    },
  },
  tipTitle: {
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "22px",
    color: "#171A1FFF",
  },
  tipText: {
    fontFamily: "Inter",
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "20px",
    color: "#171A1FFF",
  },
  AddTip: {
    margin: "1rem",
  },
  AvatarGroup: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "90%",
  },
  AvatarBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "1rem",
  },
  customAmount: {
    fontSize: 14,
    fontWeight: "400",
    lineHeight: 22,
    color: "#323743FF", // neutral-700
    backgroundColor: "#00000000", // transparent
    opacity: 1,
    borderRadius: 8, // border-xl
    borderWidth: 1,
    borderColor: "#B2B2B2FF",
    borderStyle: "solid",
  },
};

export default SplitBill;
