export const SplitData = [
  {
    id: 0,
    name: "You",
    value: 0,
    total: 340,
    currency: "AED",
  },
  {
    id: 1,
    name: "Adam",
    value: 100,
    total: 340,
    currency: "AED",
  },
  {
    id: 2,
    name: "William",
    value: 0,
    total: 340,
    currency: "AED",
  },
  {
    id: 3,
    name: "Peter",
    value: 50,
    total: 340,
    currency: "AED",
  },
  {
    id: 4,
    name: "Julia",
    value: 100,
    total: 340,
    currency: "AED",
  },
];

export const TipOptions = [
  { id: 0, value: 5, type: "number" },
  { id: 1, value: 10, type: "number" },
  { id: 2, value: 15, type: "number" },
  // {id:3,value:0,type:'custom'}
];

export const miniTacos =[{language:'Arabic',name:'سندويشات التاكو الصغيرة'},{language:'English',name:'Mini Tacos'}]
export const salamon =[{language:'Arabic',name:'سلمون نيجيري'},{language:'English',name:'Salamon Nigiri'}]
export const phil =[{language:'Arabic',name:'فيلادلفيا'},{language:'English',name:'Philadelphia'}]
export const chocolate =[{language:'Arabic',name:'كعكة الشوكولاته'},{language:'English',name:'Chocolate Brownie'}]

export const orderedItems = [
  {
    id: "1",
    name: "Mini Tacos",
    multiLanguage:JSON.stringify(miniTacos),
    qty: 1,
    price: 75,
    currency: "AED",
    paid:false,
    paidQty: 0,
    history: [
      {
        date: '10:53',
        customerId: '11091700',
        amount: 3,
      },
      {
        date: '10:55',
        customerId: 'Anonymous',
        amount: 1,
      },
    ],

  },
  {
    id: "2",
    name: "Salamon Nigiri",
    multiLanguage:
    JSON.stringify(salamon),
    qty: 2,
    price: 99,
    currency: "AED",
    paidQty: 2,
    paid:true,
    history: [
      {
        date: '10:53',
        customerId: 'Adam',
        amount: 1,
      },
      {
        date: '10:55',
        customerId: 'Julia',
        amount: 1,
      },
    ],

  },
  {
    id: "3",
    name: "Philadelphia",
    multiLanguage:
    JSON.stringify(phil),
    qty: 2,
    price: 99,
    currency: "AED",
    paidQty: 1,
    paid:false,
    history: [
      {
        date: '11:55',
        customerId: 'Peter',
        amount: 1,
      },
    ],

  },
  {
    id: "4",
    name: "Chocolate Brownie",
    multiLanguage:
    JSON.stringify(chocolate),
    qty: 1,
    price: 50,
    currency: "AED",
    paidQty: 1,
    paid:true,
    history: [
      {
        date: '10:53',
        customerId: 'Adam',
        amount: 1,
      },
      
    ],

  },
];
