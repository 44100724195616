import "./App.css";
import { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LandingPage from "./pages/LandingPage/LandingPage";
import PdfView from "./components/PdfView/PdfView";
import Invoice from "./pages/Invoice/Invoice";
import SplitBill from "./pages/splitBill/SplitBill";

function App() {
  const { i18n } = useTranslation();

  useEffect(() => {
    const selectedLanguage = localStorage.getItem("language");

    if (selectedLanguage) {
      i18n.changeLanguage(selectedLanguage);
    }
  }, []);

  return (
    <Routes>
      <Route path="/" element={<LandingPage />} />
      <Route path={"/:id"} element={<LandingPage />} />
      <Route path="/pdfView" element={<PdfView />} />
      <Route path="/payment" element={<Invoice />}></Route>
      <Route path="/splitBill" element={<SplitBill />}></Route>
    </Routes>
  );
}

export default App;
