import React, { useState, useEffect } from "react";
import Carousel from "react-material-ui-carousel";

import { Paper, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

interface SliderProps {}

const useStyles = makeStyles({
  carousel: {
    
  },
  imageContainer: {
    position: "relative",
    borderRadius: "10px",
  },
  image: {
    width: "100%",
    height: "150px",
    objectFit: "cover",
    borderRadius: "10px",
  },
});

const Slider: React.FC<SliderProps> = ({}) => {
  const classes = useStyles();
  const [activeIndex, setActiveIndex] = useState(0);

  const images = [
    require("../../assets/slider1.png"),
    require("../../assets/slider2.png"),
    require("../../assets/slider3.png"),
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 5000); // Change image every 5 seconds

    return () => clearInterval(interval);
  }, [images.length]);

  return (
    <Carousel
      className={classes.carousel}
      index={activeIndex}
      animation="slide"
      onChange={(index: any) => setActiveIndex(index)}
      indicators={false} // This hides the built-in dots (indicators)
    >
      {images.map((image, index) => (
        <img key={index} alt={`Slide ${index + 1}`} src={image} className={classes.image} />
      ))}
    </Carousel>
  );
};

export default Slider;
