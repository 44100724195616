import React, { useEffect, useState } from "react";
import { Grid, Button, Typography, createTheme } from "@mui/material";
import LanguageSwitcher from "../../components/LanguageSwitcher";
import { useTranslation } from "react-i18next";
import lightThemeOptions from "../../theme/lightThemeOptions";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setPDF } from "../../store/ducks/app";
import PoweredBy from "../../components/PoweredBy/PoweredBy";

const lightTheme = createTheme(lightThemeOptions);

const LandingPage: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handlePDF = () => {
    const pdfName = "CDM-Menu-Food-V3.pdf";
    dispatch(setPDF(pdfName));
    navigate("/pdfView", { state: { pdf: pdfName } });
  };

  const handlePayment = () => {
    navigate("/payment");
  };

  const [backgroundIndex, setBackgroundIndex] = useState(0);
  const backgroundImages = ["slider1.webp", "slider2.webp", "slider3.webp"];
  const backgroundStyle: any = (index: number) => {
    return {
      position: "absolute",
      top: 0,
      left: 0,
      backgroundImage: `url(${require("../../assets/" +
        backgroundImages[index])})`,
      height: "100%",
      width: "100%",
      objectFit: "cover",
      transition: "opacity 0.5s ease-in-out",
      opacity: backgroundIndex === index ? 0.5 : 0,
      zIndex: -2,
    };
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      setBackgroundIndex(
        (prevIndex) => (prevIndex + 1) % backgroundImages.length
      );
    }, 2000);

    return () => clearInterval(intervalId);
  }, [backgroundImages.length]);

  return (
    <div
      style={{
        position: "relative",
        display: "flex",
        alignItems:'flex-start',
        justifyContent:'space-between',
        height:window.innerHeight,
        width:window.innerWidth,
        overflow:'hidden',
      }}
    >
      <Grid
        container
        direction="column"
        alignItems="center"
        justifyContent="space-between"
        style={{height:'95%',marginTop:'5%'}}
      >
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgb(0 0 0 / 20%)", // Adjust the opacity as needed
            zIndex: -1,
          }}
        ></div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
          }}
        >
          {[0, 1, 2].map((item) => (
            <img
              style={backgroundStyle(item)}
              key={item}
              alt={"bg" + item}
              src={require("../../assets/" + backgroundImages[item])}
            />
          ))}
        </div>

        {/* Logo */}
        <Grid item>
          <img
            alt="logo"
            src={require("../../assets/CDM_white_Logo.png")}
            style={{
              minWidth: 200,
              maxWidth:300,
              borderRadius: 10,
              cursor: "pointer",
              
            }}
          />
        </Grid>

        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          rowSpacing={4}
          
        >
          {/* Buttons and Language Switcher */}
          <Grid
            container
            direction="column"
            style={{ width: "90%"}}
            rowSpacing={4}
          >
            <Grid item>
              <Button
                variant="contained"
                color="secondary"
                style={{
                  height: "45px",
                  color: lightTheme.palette.primary.dark,
                  borderRadius: "20px",
                  width: "100%",
                }}
                onClick={handlePayment}
              >
                {t("PayNow")}
              </Button>
            </Grid>

            <Grid item>
              <Button
                variant="contained"
                color="primary"
                style={{
                  height: "45px",
                  color: lightTheme.palette.secondary.dark,
                  borderRadius: "20px",
                  width: "100%",
                }}
                onClick={handlePDF}
              >
                {t("ViewMenu")}
              </Button>
            </Grid>
          </Grid>

          {/* Footer */}
          <Grid item style={{ width: "90%", textAlign: "center" }}>
            <LanguageSwitcher />
          </Grid>
          <PoweredBy color="#fff" />
        </Grid>
      </Grid>
    </div>
  );
};

export default LandingPage;
