import { useState } from "react";
import { TipOptions } from "../../constants/JsonObjects";
import {
  Box,
  Button,
  Grid,
  Input,
  InputBase,
  TextField,
  Typography,
  createTheme,
} from "@mui/material";
import lightThemeOptions from "../../theme/lightThemeOptions";
import { useTranslation } from "react-i18next";

const lightTheme = createTheme(lightThemeOptions);

function TipsComponent({ TotalAmount }: any) {
  const { t, i18n } = useTranslation();
  const [tipOption, setTipOption] = useState(-1);
  const [tipCustomValue, setTipCustomValue] = useState<number | null>(null);

  const handleTipOptions = (tipOption: any) => {
    if (tipOption.type === "custom") {
      setTipOption(-2);
    } else {
      setTipOption(tipOption.id);
    }
  };

  const calculateTip = () => {
    return tipOption === -1
      ? 0
      : tipOption === -2
      ? tipCustomValue
      : (TipOptions[tipOption].value * TotalAmount) / 100;
  };

  const handleCustomTipChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setTipOption(-2);
    setTipCustomValue(+event.target.value);
  };

  return (
    <Grid container direction="column" sx={classes.AddTip}>
      <Grid item>
        <Typography variant="h6" sx={classes.tipTitle}>
          {t("AddTip")} : {calculateTip() + t("AED")}
        </Typography>
      </Grid>

      <Grid item>
        <Typography variant="h6" sx={classes.tipText}>
          {t("TipDescription")}
        </Typography>
      </Grid>

      <Box sx={classes.tipOptions}>
        {TipOptions.map((option: any) => (
          <Button
            key={option.id}
            onClick={() => handleTipOptions(option)}
            style={{
              border:
                "1px solid " +
                (tipOption === option.id
                  ? lightTheme.palette.primary.main
                  : "#888"),
              borderRadius: 15,
              fontSize: "16px",
              fontWeight: 500,
              color:
                tipOption === option.id
                  ? lightTheme.palette.primary.main
                  : "#888",
            }}
          >
            { option.value + "%"}
          </Button>
        ))}

        {/* Custom Tip */}
        <InputBase
          fullWidth
          type="number"
          size="small"
          value={tipCustomValue}
          placeholder={t("CustomTip")}
          onChange={handleCustomTipChange}
          inputProps={{ min: 0 }} // Adding minimum value
          style={{
            textAlign: "center",
            border: "1px solid #888",
            borderRadius: 15,
            fontSize: 16,
            fontWeight: 500,
            color: "#888",
            maxWidth: "120px",
            paddingLeft:i18n.language==="Arabic" ?"1rem" : "1rem",
            paddingRight:i18n.language==="Arabic" ?"1rem" : "0rem"
          }}
        />
      </Box>
      <Typography variant="h6" sx={classes.TotalAmount}>
        {t("TotalAmount") + " : " + (TotalAmount + calculateTip())+" " + t("AED")}
      </Typography>
    </Grid>
  );
}

const classes = {
  tipTitle: {
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "22px",
    color: "#171A1FFF",
  },
  TotalAmount: {
    fontFamily: "Inter",
    fontSize: "20px",
    marginTop:'1rem',
    fontWeight: 700,
    lineHeight: "22px",
    color: "#171A1FFF",
  },
  tipText: {
    fontFamily: "Inter",
    fontSize: "13px",
    fontWeight: 400,
    lineHeight: "20px",
    color: "#171A1FFF",
  },
  AddTip: {
    margin: "1rem",
  },
  tipOptions: {
    
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "90%",
    marginTop: "0.5rem",
  },
};

export default TipsComponent;
