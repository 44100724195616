import { ThemeOptions } from "@mui/material/styles";

const lightThemeOptions: ThemeOptions = {
  direction: "ltr",
  palette: {
    primary: {
      // light: "#008495",
      // main: "#00bdd6",
      // dark: "#33cade",
      light: "#2B9CFF",
      main: "#204496",
      dark: "#10214A",
      contrastText: "#fff",
    },
    secondary: {
      light: "#FFFFFF",
      main: "#FFFFFF",
      dark: "#FFFFFF",
      contrastText: "#fff",
    },
    background: {
      paper: "#fff",
      default: "#F9F9F9",
    },
  },
  shape: {
    borderRadius: 0,
  },
};

export default lightThemeOptions;
