import {
  Typography,
  Grid,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  TextField,
  IconButton,
  createTheme,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import RedeemIcon from "@mui/icons-material/Redeem";
import AppleIcon from "@mui/icons-material/Apple";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useTranslation } from "react-i18next";
import OrderedItems from "./OrderedItems";
import PoweredBy from "../../components/PoweredBy/PoweredBy";
import { useState } from "react";
import Slider from "../../components/Slider/Slider";
import { useNavigate } from "react-router-dom";
import lightThemeOptions from "../../theme/lightThemeOptions";
import { orderedItems } from "../../constants/JsonObjects";
import TipsComponent from "../../components/tipsComponent/TipsComponent";

const lightTheme = createTheme(lightThemeOptions);

const Invoice: React.FC = () => {
  const classes = {
    Header: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      width: "100%",
      justifyContent: "center",
    },
    backButton: {
      cursor: "pointer",
      position: "absolute",
      left: "25px",
      color: lightTheme.palette.primary.main,
    },
    appleButton: {
      height: "36px",
      background: "black",
      color: "white",
      borderRadius: "20px",
      width: "100%",
      display: "flex", // Make the button a flex container
      justifyContent: "center", // Center the content horizontally
      alignItems: "center", // Center the content vertically
    },
    membershipButton: {
      
      height: "49px",
      // padding: "0 12px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      fontFamily: "Inter",
      fontSize: window.innerWidth>=370 ?"16px":"14px",
      fontWeight: 400,
      lineHeight: "26px",
      color: "#FFFFFFFF",
      background: "#BCAF98FF",
      opacity: 1,
      border: "none",
      borderRadius: "11px",
      width:'100%',
      "&:hover": {
        color: "#FFFFFFFF",
        background: "#A28F70FF",
      },
      "&:active": {
        color: "#FFFFFFFF",
        background: "#827155FF",
      },
      "&:disabled": {
        opacity: 0.4,
      },
    },
    billTitle: {
      fontFamily: "Inter",
      fontSize: "22px",
      fontWeight: 700,
      lineHeight: "34px",
      color: "#171A1FFF",
    },
    splitButton: {
      height: "30px",
      borderRadius: "15px",
      padding: "0 12px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      fontFamily: "Inter",
      fontSize: "16px",
      fontWeight: 400,
      lineHeight: "26px",
      color: "#FFFFFFFF",
      background: "#204496",
      opacity: 1,
      border: "none",
      "&:hover": {
        color: "#FFFFFFFF",
        background: "#3155A8",
      },
      "&:active": {
        color: "#FFFFFFFF",
        background: "#0095A9FF",
      },
      "&:disabled": {
        opacity: 0.4,
      },
    },
  };
  
  const { t, i18n } = useTranslation();
  const currLanguage = i18n.language;
  
  const navigate = useNavigate();

  const [splitPayment, setSplitPayment] = useState(false);
  const [trackPayment, setTrackPayment] = useState(false);
  const [error, setError] = useState({name:false});

  const [open, setOpen] = useState(false);
  const [customerName, setCustomerName] = useState<string>("");

  const handleMembership = () => {
    // navigate("/membership");
  };
  const handleSplitBill = () => {
    setOpen(true);
  };
  const handlePayment = () => {
    // navigate("/membership");
  };

  const handleCustomerNameChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCustomerName(event.target.value);
  };

  const handleConfirmName = () => {
    if (customerName !== "") {
      setOpen(false);
      setError({name:false})
      navigate("/splitBill");
    }else{
      setError({name:true})
    }
  };

  const calculateTotalAmount = () => {
    const total = orderedItems.reduce(
      (total, item) => total + item.qty * item.price,
      0
    );
    return total;
  };

  return (
    <>
      <div
        style={{
          minHeight: window.innerHeight,
          overflowX:'hidden'
        }}
      >
        <Grid
          container
          direction="column"
          alignItems="center"
          padding={1}
          rowSpacing={2}
          justifyContent="space-between"
        >
          {/* Logo */}
          <Grid item sx={classes.Header}>
            <ArrowBackIosNewIcon
              sx={classes.backButton}
              onClick={() => navigate("/")}
            />
            <img
              alt="logo"
              src={require("../../assets/CDM_AbuDhabi_Logo.png")}
              style={{
                width: 200,
                borderRadius: 10,
                cursor: "pointer",
              }}
            />
          </Grid>

          {/* Slider */}
          <Grid
            item
            sx={{
              minHeight: "150px",
              width: "100%",
            }}
          >
            <Slider />
          </Grid>

          {/* Loyalty Button */}
          <Grid item sx={{width:'100%'}}>
            <Button
              sx={classes.membershipButton}
              // variant="contained"
              onClick={handleMembership}
            >
              <RedeemIcon style={{ marginRight: i18n.language==="Arabic" ? "0px":"8px", marginLeft: i18n.language==="Arabic" ? "8px":"0px"}} /> {t("MemberShip")}
            </Button>
          </Grid>
        </Grid>

        {/* Split Bill Button */}
        <Grid
          sx={{
            display: "flex",
            flexDirection: currLanguage ==="Arabic" ? "row-reverse": "row",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
            
          }}
          alignItems="center"
          paddingY={1}
          paddingX={3}
          rowSpacing={2}
        >
          {/* Left Column */}
          <Grid item xs={6}>
            <Typography variant="h6" sx={classes.billTitle}>
              {t("PayBill")}
            </Typography>
          </Grid>

          {/* Right Column */}
          <Grid item xs={6} alignItems="flex-end">
            <Button
              sx={classes.splitButton}
              variant="contained"
              onClick={handleSplitBill}
            >
              {t("Split")}
            </Button>
          </Grid>
        </Grid>

        {/* Ordered Items Table */}
        <Grid
          container
          sx={{ marign: 0, padding: 0 }}
          direction="column"
          alignItems="center"
          rowSpacing={0}
        >
          <OrderedItems />
        </Grid>

        {/* Add a Tip */}
        <TipsComponent TotalAmount={calculateTotalAmount()}></TipsComponent>

        {/* Payment Buttons */}
        <Grid container direction="column" alignItems="center" rowSpacing={2}>
          {/* Apple Pay */}
          <Grid item style={{ width: "90%" }}>
            <Button
              variant="contained"
              sx={classes.appleButton}
              onClick={handlePayment}
            >
              <AppleIcon style={{ marginRight: "8px" }} />
              {t("Pay")}
            </Button>
          </Grid>

          {/* Credit Card Payment */}
          <Grid item style={{ width: "90%" }}>
            <Button
              variant="contained"
              color="primary"
              style={{
                height: "36px",
                borderRadius: "20px",
                width: "100%",
              }}
              onClick={handlePayment}
            >
              {t("CardPay")}
            </Button>
          </Grid>
        </Grid>

        {/* Powered By */}
        <Grid item style={{ textAlign: "center" }}>
        <PoweredBy color="#999"/>
        </Grid>
      </div>

      {/* Customer Name Modal */}
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        PaperProps={{ sx: { borderRadius: "10px" } }}
      >
        <DialogContent>
          <IconButton
            style={{
              position: "absolute",
              top: 0,
              right: 0,
            }}
            color="error"
            onClick={() => setOpen(false)}
          >
            <ClearIcon />
          </IconButton>

          <Typography
            style={{
              marginTop: "10px",
              marginBottom: "10px",
              textAlign: "center",
            }}
          >
            {t("EnterName")}
          </Typography>

          <DialogContentText
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <TextField
              fullWidth
              size="small"
              value={customerName}
              placeholder={t("EnterYourName")}
              onChange={handleCustomerNameChange}
              style={{ marginBottom: "10px" }}
            />

            <Button
              variant="contained"
              color="primary"
              style={{
                height: "36px",
                borderRadius:'10px'
              }}
              onClick={() => {
                handleConfirmName();
              }}
            >
              {t("Confirm")}
            </Button>
            {error.name && <Typography
            style={{
              marginTop: "10px",
              textAlign: "center",
              color:'red'
            }}
          >
            {t("enterNamePrompt")}
          </Typography>}
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
};


export default Invoice;
